import React, { Component, Fragment } from 'react';
import { navigate } from 'gatsby';

import * as routes from '../../constants/routes';
import { withFirebase } from '../Firebase/FirebaseContext';
import Rules from './Rules';
import { PasswordForgetLink } from '../PasswordForget';
import { SignUpLink } from '../SignUp';
import uniqid from 'uniqid';
import styled from 'styled-components';
import { Background } from '../../pages/trainings';
import Button from '@material-ui/core/Button';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  agree: false
};

const Styled = styled.div`
  * {
    color: white;
    .jumbotron{
      *{
       color: black;
      }
     }
  }
   input {
    width: 100%;
    border: none
    border-bottom: 1px solid black;
    appearance: none;
    color:black;
  }
  button{
      color:black;
  }
`;

class SignInForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }));
        navigate(routes.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  componentDidMount() {
    if (this.props.firebase && this.props.firebase.firebase.auth) {
      this.props.firebase.firebase
        .auth()
        .getRedirectResult()
        .then(result => {
          if (result && result.user) {
            this.login(result);
          }
        });
    }
  }

  facebookSignIn = () => {
    this.props.firebase.doSignInWithFacebook().then(this.login);
  };

  googleSignIn = () => {
    this.props.firebase.doSignInWithGoogle().then(this.login);
  };

  login = async ({ user }) => {
    try {
      const existingUser = await this.props.firebase.getUser(user.uid);
      if (!existingUser) {
        await this.props.firebase.doCreateUser(
          user.uid,
          user.displayName,
          user.email || uniqid(),
          user.photoURL
        );
      }
      this.setState(() => ({ ...INITIAL_STATE }));

      setTimeout(() => {
        navigate(routes.TRAININGS);
      }, 5000);
    } catch (e) {
      navigate(routes.TRAININGS);
    }
  };

  onAgree = () => {
    this.setState({ agree: true });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <Styled>
        <div className="container">
          <Background>
            {this.state.agree ? (
              <Fragment>
                <form onSubmit={this.onSubmit}>
                  <h2>Зайти:</h2>
                  <input
                    name="email"
                    value={email}
                    onChange={event => this.setState({ [event.target.name]: event.target.value })}
                    type="text"
                    placeholder="Електронна пошта"
                  />
                  <input
                    name="password"
                    value={password}
                    onChange={event => this.setState({ [event.target.name]: event.target.value })}
                    type="password"
                    placeholder="Пароль"
                  />
                  <button disabled={isInvalid} type="submit">
                    Вхід
                  </button>
                  {error && <p>{error.message}</p>}
                </form>
                <PasswordForgetLink />
                <SignUpLink />
                <hr />
                {/*<h4>Увійти через Facebook:</h4>*/}
                {/*<Button*/}
                {/*  onClick={this.facebookSignIn}*/}
                {/*  style={{ backgroundColor: '#4267b2', color: 'white', fontSize: 16, padding: 10 }}*/}
                {/*>*/}
                {/*  Facebook*/}
                {/*</Button>{' '}*/}
                <hr />
                <h4>Увійти через Google:</h4>
                <Button
                  onClick={this.googleSignIn}
                  style={{ border: 'white 1px solid', color: 'black', fontSize: 16, padding: 10 }}
                >
                  Google
                </Button>
              </Fragment>
            ) : (
              <>
                <h3>Увага! Ознайомтеся з правилами:</h3>
                <hr />
                <Rules onAgree={this.onAgree} />
              </>
            )}
          </Background>
        </div>
      </Styled>
    );
  }
}

export default withFirebase(SignInForm);
