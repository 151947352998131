import React from 'react';

import Layout from '../components/layout';
import SignInForm from '../components/SignIn';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 10px;
`;
const SignInPage = () => (
  <Wrapper>
    <SignInForm />
  </Wrapper>
);

export default () => (
  <Layout>
    <SignInPage />
  </Layout>
);
